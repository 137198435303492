export class ConnectActionDto {
  id: string;
}

export class CreateActionCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateActionDto {
  title: string;
  type: ActionType;
  customActionMasterId?: string | null;
  creatorAdminUserId?: string | null;
  managerAdminUserId?: string | null;
  executedDate?: Date | null;
  status: ActionStatus;
  company: CreateActionCompanyRelationInputDto;
}

export class UpdateActionDto {
  title?: string | null;
  type?: ActionType | null;
  customActionMasterId?: string | null;
  creatorAdminUserId?: string | null;
  managerAdminUserId?: string | null;
  executedDate?: Date | null;
  status?: ActionStatus | null;
}
export class ConnectAdminUserMasterDto {
  id: number;
}
export class CreateAdminUserMasterDto {
  name: string;
}
export class UpdateAdminUserMasterDto {
  name?: string | null;
}
export class ConnectAdminUserDto {
  id: string;
}

export class CreateAdminUserCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateAdminUserAdminUserMasterRelationInputDto {
  connect: ConnectAdminUserMasterDto;
}

export class CreateAdminUserDto {
  id: string;
  email: string;
  name: string;
  company: CreateAdminUserCompanyRelationInputDto;
  adminUserMaster?: CreateAdminUserAdminUserMasterRelationInputDto | null;
  icon?: string | null;
}

export class UpdateAdminUserAdminUserMasterRelationInputDto {
  connect: ConnectAdminUserMasterDto;
}

export class UpdateAdminUserDto {
  email?: string | null;
  name?: string | null;
  adminUserMaster?: UpdateAdminUserAdminUserMasterRelationInputDto | null;
  icon?: string | null;
}
export class ConnectAlertMasterDto {
  id: number;
}
export class CreateAlertMasterDto {
  name: string;
  storeIntegration: boolean;
  description: string;
  comment: string;
  prominenceColor: string;
}
export class UpdateAlertMasterDto {
  name?: string | null;
  storeIntegration?: boolean | null;
  description?: string | null;
  comment?: string | null;
  prominenceColor?: string | null;
}
export class ConnectAlertSettingDto {
  id: string;
}

export class CreateAlertSettingAlertMasterRelationInputDto {
  connect: ConnectAlertMasterDto;
}
export class CreateAlertSettingCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateAlertSettingDto {
  isEnabled?: boolean | null;
  numbers: string;
  excludedStoreIds: string;
  alertMaster: CreateAlertSettingAlertMasterRelationInputDto;
  company: CreateAlertSettingCompanyRelationInputDto;
}
export class UpdateAlertSettingDto {
  isEnabled?: boolean | null;
  numbers?: string | null;
  excludedStoreIds?: string | null;
}
export class ConnectAlertDto {
  id: string;
}

export class CreateAlertAlertMasterRelationInputDto {
  connect: ConnectAlertMasterDto;
}
export class CreateAlertCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateAlertProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateAlertSkuRelationInputDto {
  connect: ConnectSkuDto;
}

export class CreateAlertDto {
  userDeleted?: boolean | null;
  alertMaster: CreateAlertAlertMasterRelationInputDto;
  company: CreateAlertCompanyRelationInputDto;
  product: CreateAlertProductRelationInputDto;
  sku?: CreateAlertSkuRelationInputDto | null;
}
export class UpdateAlertDto {
  userDeleted?: boolean | null;
}
export class ConnectAttributeDto {
  id: string;
}

export class CreateAttributeCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateAttributeDto {
  number: number;
  name: string;
  code?: string | null;
  company: CreateAttributeCompanyRelationInputDto;
}
export class UpdateAttributeDto {
  number?: number | null;
  name?: string | null;
  code?: string | null;
}
export class ConnectBaseIntegrationDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateBaseIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateBaseIntegrationDto {
  refreshToken: string;
  janCode: BaseJanCodeRecognitionKey;
  stockSynchronized?: boolean | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  company: CreateBaseIntegrationCompanyRelationInputDto;
}

export class UpdateBaseIntegrationDto {
  refreshToken?: string | null;
  janCode?: BaseJanCodeRecognitionKey | null;
  stockSynchronized?: boolean | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
}
export class ConnectBatchResumeStatusDto {
  id: string;
}

export class CreateBatchResumeStatusCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateBatchResumeStatusDto {
  service: string;
  phase: string;
  start: Date;
  end: Date;
  company: CreateBatchResumeStatusCompanyRelationInputDto;
}
export class UpdateBatchResumeStatusDto {
  service?: string | null;
  phase?: string | null;
  start?: Date | null;
  end?: Date | null;
}
export class ConnectBrandCategoryDto {
  id: string;
}

export class CreateBrandCategoryBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateBrandCategoryCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateBrandCategoryCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateBrandCategoryDto {
  index: number;
  brand: CreateBrandCategoryBrandRelationInputDto;
  category: CreateBrandCategoryCategoryRelationInputDto;
  company: CreateBrandCategoryCompanyRelationInputDto;
}

export class BulkUpdateBrandCategoryDto {
  brandCategoryId: string;
  data: UpdateBrandCategoryDto;
}
export class UpdateBrandCategoryDto {
  index?: number | null;
}
export class ConnectBrandDto {
  id: number;
}

export class CreateBrandCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateBrandDto {
  name: string;
  code?: string | null;
  company: CreateBrandCompanyRelationInputDto;
}
export class UpdateBrandDto {
  name?: string | null;
  code?: string | null;
}
export class ConnectBudgetSeasonRateDto {
  id: string;
}

export class CreateBudgetSeasonRateCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateBudgetSeasonRateSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateBudgetSeasonRateDto {
  year: number;
  month: number;
  rate: number;
  company: CreateBudgetSeasonRateCompanyRelationInputDto;
  season: CreateBudgetSeasonRateSeasonRelationInputDto;
}
export class UpdateBudgetSeasonRateDto {
  year?: number | null;
  month?: number | null;
  rate?: number | null;
}
export class ConnectBudgetWeekRateDto {
  id: string;
}

export class CreateBudgetWeekRateCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateBudgetWeekRateDto {
  year: number;
  month: number;
  weekday: number;
  weekend: number;
  company: CreateBudgetWeekRateCompanyRelationInputDto;
}
export class UpdateBudgetWeekRateDto {
  year?: number | null;
  month?: number | null;
  weekday?: number | null;
  weekend?: number | null;
}
export class ConnectCategoryDto {
  id: number;
}

export class CreateCategoryCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateCategoryDto {
  name: string;
  code?: string | null;
  company: CreateCategoryCompanyRelationInputDto;
}
export class UpdateCategoryDto {
  name?: string | null;
  code?: string | null;
}
export class ConnectColorDto {
  id: string;
}

export class CreateColorCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateColorDto {
  name?: string | null;
  code: string;
  company: CreateColorCompanyRelationInputDto;
}
export class UpdateColorDto {
  name?: string | null;
  code?: string | null;
}
export class ConnectCommentDto {
  id: string;
}

export class CreateCommentCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateCommentMemberRelationInputDto {
  connect: ConnectMemberDto;
}
export class CreateCommentProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateCommentSkuRelationInputDto {
  connect: ConnectSkuDto;
}
export class CreateCommentStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateCommentDto {
  age: string;
  figure: string;
  height: string;
  content: string;
  gender: string;
  positivity: number;
  servedAt: Date;
  target: number;
  category: number;
  company: CreateCommentCompanyRelationInputDto;
  member?: CreateCommentMemberRelationInputDto | null;
  product: CreateCommentProductRelationInputDto;
  sku?: CreateCommentSkuRelationInputDto | null;
  store?: CreateCommentStoreRelationInputDto | null;
}

export class UpdateCommentMemberRelationInputDto {
  connect: ConnectMemberDto;
}
export class UpdateCommentSkuRelationInputDto {
  connect: ConnectSkuDto;
}

export class UpdateCommentDto {
  age?: string | null;
  figure?: string | null;
  height?: string | null;
  content?: string | null;
  gender?: string | null;
  positivity?: number | null;
  servedAt?: Date | null;
  target?: number | null;
  category?: number | null;
  member?: UpdateCommentMemberRelationInputDto | null;
  sku?: UpdateCommentSkuRelationInputDto | null;
}
export class ConnectCompanyDto {
  id?: string | null;
  email?: string | null;
}
export class CreateCompanyDto {
  id: string;
  email: string;
  name: string;
  address?: string | null;
  icon?: string | null;
  phoneNumber?: string | null;
  postalCode?: string | null;
}
export class UpdateCompanyDto {
  email?: string | null;
  name?: string | null;
  address?: string | null;
  icon?: string | null;
  phoneNumber?: string | null;
  postalCode?: string | null;
}
export class ConnectCustomActionDetailDto {
  id: string;
}

export class CreateCustomActionDetailSkuRelationInputDto {
  connect: ConnectSkuDto;
}
export class CreateCustomActionDetailProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateCustomActionDetailCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateCustomActionDetailDto {
  sku?: CreateCustomActionDetailSkuRelationInputDto | null;
  product: CreateCustomActionDetailProductRelationInputDto;
  company: CreateCustomActionDetailCompanyRelationInputDto;
}
export class UpdateCustomActionDetailDto {}
export class ConnectCustomActionMasterDto {
  id: string;
}

export class CreateCustomActionMasterCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateCustomActionMasterDto {
  name: string;
  company: CreateCustomActionMasterCompanyRelationInputDto;
}
export class UpdateCustomActionMasterDto {
  name?: string | null;
}
export class ConnectCustomIntegrationDto {
  id: string;
}

export class CreateCustomIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateCustomIntegrationServiceRelationInputDto {
  connect: ConnectServiceDto;
}

export class CreateCustomIntegrationDto {
  productBargain: number;
  headCouponBargain: number;
  headPointBargain: number;
  headBundleBargain: number;
  headRoundingBargain: number;
  company: CreateCustomIntegrationCompanyRelationInputDto;
  service: CreateCustomIntegrationServiceRelationInputDto;
}
export class UpdateCustomIntegrationDto {
  productBargain?: number | null;
  headCouponBargain?: number | null;
  headPointBargain?: number | null;
  headBundleBargain?: number | null;
  headRoundingBargain?: number | null;
}
export class ConnectCustomTagMasterDto {
  id: number;
}

export class CreateCustomTagMasterCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateCustomTagMasterDto {
  name: string;
  company: CreateCustomTagMasterCompanyRelationInputDto;
}
export class UpdateCustomTagMasterDto {
  name?: string | null;
}
export class ConnectCustomTagDto {
  id: number;
}

export class CreateCustomTagCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateCustomTagCustomTagMasterRelationInputDto {
  connect: ConnectCustomTagMasterDto;
}
export class CreateCustomTagProductRelationInputDto {
  connect: ConnectProductDto;
}

export class CreateCustomTagDto {
  creatorAdminUserId?: string | null;
  company: CreateCustomTagCompanyRelationInputDto;
  customTagMaster: CreateCustomTagCustomTagMasterRelationInputDto;
  product: CreateCustomTagProductRelationInputDto;
}
export class CustomTagsToProductsDto {
  customTagMaterIds: number[];
  productIds: string[];
  creatorAdminUserId?: string | null;
}
export class UpdateCustomTagDto {
  creatorAdminUserId?: string | null;
}
export class ConnectFactoryDto {
  id: string;
}

export class CreateFactoryCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateFactoryDto {
  name: string;
  address: string;
  phoneNumber: string;
  code: string;
  company: CreateFactoryCompanyRelationInputDto;
}
export class UpdateFactoryDto {
  name?: string | null;
  address?: string | null;
  phoneNumber?: string | null;
  code?: string | null;
}
export class ConnectFutureshopIntegrationDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateFutureshopIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateFutureshopIntegrationDto {
  shopKey: string;
  clientId: string;
  clientSecret: string;
  janCode?: FutureshopJanCodeRecognitionKey | null;
  headProductCouponDiscountTotal: number;
  headVolumeDiscountTotal: number;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  stockSynchronized?: boolean | null;
  company: CreateFutureshopIntegrationCompanyRelationInputDto;
}

export class UpdateFutureshopIntegrationDto {
  shopKey?: string | null;
  clientId?: string | null;
  clientSecret?: string | null;
  janCode?: FutureshopJanCodeRecognitionKey | null;
  headProductCouponDiscountTotal?: number | null;
  headVolumeDiscountTotal?: number | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  stockSynchronized?: boolean | null;
}
export class ConnectIndividualAlertSettingDto {
  id: string;
}

export class CreateIndividualAlertSettingAlertMasterRelationInputDto {
  connect: ConnectAlertMasterDto;
}
export class CreateIndividualAlertSettingProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateIndividualAlertSettingCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateIndividualAlertSettingDto {
  numbers: string;
  excludedStoreIds: string;
  alertMaster: CreateIndividualAlertSettingAlertMasterRelationInputDto;
  product: CreateIndividualAlertSettingProductRelationInputDto;
  company: CreateIndividualAlertSettingCompanyRelationInputDto;
}
export class UpdateIndividualAlertSettingDto {
  numbers?: string | null;
  excludedStoreIds?: string | null;
}
export class ConnectInterstoreTransferGroupDto {
  id: string;
}
export class CreateInterstoreTransferGroupDto {
  companyId: string;
  name: string;
}
export class UpdateInterstoreTransferGroupDto {
  companyId?: string | null;
  name?: string | null;
}
export class ConnectItemNumberLatestDto {
  id: number;
}

export class CreateItemNumberLatestCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateItemNumberLatestDto {
  seasonCode: string;
  brandCode: string;
  categoryCode: string;
  attribute1Code: string;
  attribute2Code: string;
  attribute3Code: string;
  company: CreateItemNumberLatestCompanyRelationInputDto;
}
export class UpdateItemNumberLatestDto {
  seasonCode?: string | null;
  brandCode?: string | null;
  categoryCode?: string | null;
  attribute1Code?: string | null;
  attribute2Code?: string | null;
  attribute3Code?: string | null;
}
export class ConnectItemNumberLogicDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateItemNumberLogicCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateItemNumberLogicDto {
  format: string;
  company: CreateItemNumberLogicCompanyRelationInputDto;
}
export class UpdateItemNumberLogicCustomDto {
  format?: string | null;
  isEnabled?: boolean;
}
export class UpdateItemNumberLogicDto {
  format?: string | null;
}
export class ConnectJanCodeLogicDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateJanCodeLogicCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateJanCodeLogicDto {
  prefix: number;
  format?: string | null;
  company: CreateJanCodeLogicCompanyRelationInputDto;
}
export class UpdateJanCodeLogicDto {
  prefix?: number | null;
  format?: string | null;
}
export class ConnectLogizardIntegrationDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateLogizardIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateLogizardIntegrationDto {
  userId: string;
  groupId: string;
  connectionId: string;
  ownerId: string;
  areaId: string;
  authKey: string;
  itemId: LogizardJanCodeRecognitionKey;
  key1?: LogizardJanCodeRecognitionKey | null;
  key2?: LogizardJanCodeRecognitionKey | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  company: CreateLogizardIntegrationCompanyRelationInputDto;
}

export class UpdateLogizardIntegrationDto {
  userId?: string | null;
  groupId?: string | null;
  connectionId?: string | null;
  ownerId?: string | null;
  areaId?: string | null;
  authKey?: string | null;
  itemId?: LogizardJanCodeRecognitionKey | null;
  key1?: LogizardJanCodeRecognitionKey | null;
  key2?: LogizardJanCodeRecognitionKey | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
}
export class ConnectMakeshopIntegrationDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateMakeshopIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateMakeshopIntegrationDto {
  shopId: string;
  productToken: string;
  orderToken: string;
  janCode: MakeshopJanCodeRecognitionKey;
  stockSynchronized?: boolean | null;
  company: CreateMakeshopIntegrationCompanyRelationInputDto;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
}

export class UpdateMakeshopIntegrationDto {
  shopId?: string | null;
  productToken?: string | null;
  orderToken?: string | null;
  janCode?: MakeshopJanCodeRecognitionKey | null;
  stockSynchronized?: boolean | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
}
export class ConnectMemberDto {
  id: string;
}

export class CreateMemberCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateMemberStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateMemberDto {
  name: string;
  company: CreateMemberCompanyRelationInputDto;
  store: CreateMemberStoreRelationInputDto;
}
export class UpdateMemberDto {
  name?: string | null;
}
export class ConnectNextEngineIntegrationDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateNextEngineIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateNextEngineIntegrationDto {
  accessToken: string;
  refreshToken: string;
  janCode: NextEngineJanCodeRecognitionKey;
  stockSynchronized?: boolean | null;
  company: CreateNextEngineIntegrationCompanyRelationInputDto;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
}

export class UpdateNextEngineIntegrationDto {
  accessToken?: string | null;
  refreshToken?: string | null;
  janCode?: NextEngineJanCodeRecognitionKey | null;
  stockSynchronized?: boolean | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
}
export class ConnectOrderInstructionDetailDto {
  id: string;
}

export class CreateOrderInstructionDetailCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateOrderInstructionDetailOrderInstructionsRelationInputDto {
  connect: ConnectOrderInstructionDto;
}
export class CreateOrderInstructionDetailOrderProductRelationInputDto {
  connect: ConnectOrderProductDto;
}
export class CreateOrderInstructionDetailOrderSkuRelationInputDto {
  connect: ConnectOrderSkuDto;
}

export class CreateOrderInstructionDetailDto {
  company: CreateOrderInstructionDetailCompanyRelationInputDto;
  orderInstructions: CreateOrderInstructionDetailOrderInstructionsRelationInputDto;
  orderProduct?: CreateOrderInstructionDetailOrderProductRelationInputDto | null;
  orderSku?: CreateOrderInstructionDetailOrderSkuRelationInputDto | null;
}

export class UpdateOrderInstructionDetailOrderInstructionsRelationInputDto {
  connect: ConnectOrderInstructionDto;
}
export class UpdateOrderInstructionDetailOrderProductRelationInputDto {
  connect: ConnectOrderProductDto;
}
export class UpdateOrderInstructionDetailOrderSkuRelationInputDto {
  connect: ConnectOrderSkuDto;
}

export class UpdateOrderInstructionDetailDto {
  orderInstructions?: UpdateOrderInstructionDetailOrderInstructionsRelationInputDto | null;
  orderProduct?: UpdateOrderInstructionDetailOrderProductRelationInputDto | null;
  orderSku?: UpdateOrderInstructionDetailOrderSkuRelationInputDto | null;
}
export class ConnectOrderInstructionDto {
  id: string;
}

export class CreateOrderInstructionCustomDto {
  orderNumber: string;
  orderedDate: Date;
  stockedDate: Date;
  company: CreateOrderInstructionCompanyRelationInputDto;
  warehouse: CreateOrderInstructionWarehouseRelationInputDto;
  factory: CreateOrderInstructionFactoryRelationInputDto;
  orderProductIds: number[];
}

export class CreateOrderInstructionCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateOrderInstructionWarehouseRelationInputDto {
  connect: ConnectWarehouseDto;
}
export class CreateOrderInstructionFactoryRelationInputDto {
  connect: ConnectFactoryDto;
}

export class CreateOrderInstructionDto {
  orderNumber: string;
  orderedDate: Date;
  stockedDate: Date;
  title?: string | null;
  creatorAdminUserId?: string | null;
  managerAdminUserId?: string | null;
  company: CreateOrderInstructionCompanyRelationInputDto;
  warehouse?: CreateOrderInstructionWarehouseRelationInputDto | null;
  factory?: CreateOrderInstructionFactoryRelationInputDto | null;
}

export class UpdateOrderInstructionStatusWithOrderProductDto {
  orderInstructionId: string;
  status: OrderProductStockedStatus;
}

export class UpdateOrderInstructionWarehouseRelationInputDto {
  connect: ConnectWarehouseDto;
}
export class UpdateOrderInstructionFactoryRelationInputDto {
  connect: ConnectFactoryDto;
}

export class UpdateOrderInstructionDto {
  orderNumber?: string | null;
  orderedDate?: Date | null;
  stockedDate?: Date | null;
  title?: string | null;
  creatorAdminUserId?: string | null;
  managerAdminUserId?: string | null;
  warehouse?: UpdateOrderInstructionWarehouseRelationInputDto | null;
  factory?: UpdateOrderInstructionFactoryRelationInputDto | null;
}
export class ConnectOrderProductDto {
  id: number;
}

export class CreateOrderProductBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateOrderProductBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateOrderProductCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateOrderProductCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateOrderProductProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateOrderProductSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateOrderProductDto {
  year: number;
  month: number;
  stockedDate: Date;
  orderedDate?: Date | null;
  stockedStatus?: OrderProductStockedStatus | null;
  brandCategory?: CreateOrderProductBrandCategoryRelationInputDto | null;
  brand: CreateOrderProductBrandRelationInputDto;
  category: CreateOrderProductCategoryRelationInputDto;
  company: CreateOrderProductCompanyRelationInputDto;
  product: CreateOrderProductProductRelationInputDto;
  season: CreateOrderProductSeasonRelationInputDto;
}

export class UpdateOrderProductStatusWithOrderInstructionDto {
  orderInstructionId: string;
  stockedStatus: OrderProductStockedStatus;
}

export class UpdateOrderProductDto {
  year?: number | null;
  month?: number | null;
  stockedDate?: Date | null;
  orderedDate?: Date | null;
  stockedStatus?: OrderProductStockedStatus | null;
}
export class ConnectOrderSkuDto {
  id: number;
}

export class CreateOrderSkuBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateOrderSkuBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateOrderSkuCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateOrderSkuCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateOrderSkuOrderProductRelationInputDto {
  connect: ConnectOrderProductDto;
}
export class CreateOrderSkuProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateOrderSkuSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}
export class CreateOrderSkuSkuRelationInputDto {
  connect: ConnectSkuDto;
}

export class CreateOrderSkuDto {
  orderQuantity: number;
  orderCost: number;
  brandCategory?: CreateOrderSkuBrandCategoryRelationInputDto | null;
  brand: CreateOrderSkuBrandRelationInputDto;
  category: CreateOrderSkuCategoryRelationInputDto;
  company: CreateOrderSkuCompanyRelationInputDto;
  orderProduct: CreateOrderSkuOrderProductRelationInputDto;
  product: CreateOrderSkuProductRelationInputDto;
  season: CreateOrderSkuSeasonRelationInputDto;
  sku: CreateOrderSkuSkuRelationInputDto;
}
export class UpdateOrderSkuDto {
  orderQuantity?: number | null;
  orderCost?: number | null;
}
export class ConnectProductCustomValueSettingDto {
  id: string;
}

export class CreateProductCustomValueSettingCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateProductCustomValueSettingDto {
  name: string;
  type: CustomValueType;
  company: CreateProductCustomValueSettingCompanyRelationInputDto;
}

export class UpdateProductCustomValueSettingDto {
  name?: string | null;
  type?: CustomValueType | null;
}
export class ConnectProductCustomValueDto {
  id: string;
}

export class CreateProductCustomValueProductCustomValueSettingRelationInputDto {
  connect: ConnectProductCustomValueSettingDto;
}
export class CreateProductCustomValueProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateProductCustomValueCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateProductCustomValueDto {
  value: string;
  productCustomValueSetting: CreateProductCustomValueProductCustomValueSettingRelationInputDto;
  product: CreateProductCustomValueProductRelationInputDto;
  company: CreateProductCustomValueCompanyRelationInputDto;
}
export class UpdateProductCustomValueDto {
  value?: string | null;
}
export class ConnectProductImageDto {
  id: string;
}

export class CreateProductImageColorRelationInputDto {
  connect: ConnectColorDto;
}
export class CreateProductImageCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateProductImageProductRelationInputDto {
  connect: ConnectProductDto;
}

export class CreateProductImageDto {
  index: number;
  fileName: string;
  color?: CreateProductImageColorRelationInputDto | null;
  company: CreateProductImageCompanyRelationInputDto;
  product: CreateProductImageProductRelationInputDto;
}
export class UpdateProductImageDto {
  index?: number | null;
  fileName?: string | null;
}
export class ConnectProductDto {
  id: string;
}

export class CreateProductAttribute1RelationInputDto {
  connect: ConnectAttributeDto;
}
export class CreateProductAttribute2RelationInputDto {
  connect: ConnectAttributeDto;
}
export class CreateProductAttribute3RelationInputDto {
  connect: ConnectAttributeDto;
}
export class CreateProductBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateProductBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateProductCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateProductCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateProductFactoryRelationInputDto {
  connect: ConnectFactoryDto;
}
export class CreateProductSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateProductDto {
  name?: string | null;
  itemNumber?: string | null;
  gender?: number | null;
  store?: ProductStore | null;
  price?: number | null;
  salesPrice?: number | null;
  salesStartedAt?: Date | null;
  salesEndedAt?: Date | null;
  salesPriceUpdatedAt?: Date | null;
  description?: string | null;
  composition?: string | null;
  freeComment?: string | null;
  directionMemo?: string | null;
  leadTime?: number | null;
  minimumOrderQuantity?: number | null;
  status?: ProductStatus | null;
  shopifyProductId?: string | null;
  attribute1?: CreateProductAttribute1RelationInputDto | null;
  attribute2?: CreateProductAttribute2RelationInputDto | null;
  attribute3?: CreateProductAttribute3RelationInputDto | null;
  brandCategory?: CreateProductBrandCategoryRelationInputDto | null;
  brand?: CreateProductBrandRelationInputDto | null;
  category?: CreateProductCategoryRelationInputDto | null;
  company: CreateProductCompanyRelationInputDto;
  factory?: CreateProductFactoryRelationInputDto | null;
  season?: CreateProductSeasonRelationInputDto | null;
}

export class UpdateProductAttribute1RelationInputDto {
  connect: ConnectAttributeDto;
}
export class UpdateProductAttribute2RelationInputDto {
  connect: ConnectAttributeDto;
}
export class UpdateProductAttribute3RelationInputDto {
  connect: ConnectAttributeDto;
}
export class UpdateProductBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class UpdateProductBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class UpdateProductCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class UpdateProductCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class UpdateProductFactoryRelationInputDto {
  connect: ConnectFactoryDto;
}
export class UpdateProductSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class UpdateProductDto {
  name?: string | null;
  itemNumber?: string | null;
  gender?: number | null;
  store?: ProductStore | null;
  price?: number | null;
  salesPrice?: number | null;
  salesStartedAt?: Date | null;
  salesEndedAt?: Date | null;
  salesPriceUpdatedAt?: Date | null;
  description?: string | null;
  composition?: string | null;
  freeComment?: string | null;
  directionMemo?: string | null;
  leadTime?: number | null;
  minimumOrderQuantity?: number | null;
  status?: ProductStatus | null;
  shopifyProductId?: string | null;
  attribute1?: UpdateProductAttribute1RelationInputDto | null;
  attribute2?: UpdateProductAttribute2RelationInputDto | null;
  attribute3?: UpdateProductAttribute3RelationInputDto | null;
  brandCategory?: UpdateProductBrandCategoryRelationInputDto | null;
  brand?: UpdateProductBrandRelationInputDto | null;
  category?: UpdateProductCategoryRelationInputDto | null;
  company?: UpdateProductCompanyRelationInputDto | null;
  factory?: UpdateProductFactoryRelationInputDto | null;
  season?: UpdateProductSeasonRelationInputDto | null;
}
export class ConnectResultRateDto {
  id: string;
}

export class CreateResultRateBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateResultRateBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateResultRateCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateResultRateCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateResultRateSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}
export class CreateResultRateStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateResultRateDto {
  year: number;
  month: number;
  weekNumber: number;
  saleRate: number;
  brandCategory: CreateResultRateBrandCategoryRelationInputDto;
  brand: CreateResultRateBrandRelationInputDto;
  category: CreateResultRateCategoryRelationInputDto;
  company: CreateResultRateCompanyRelationInputDto;
  season: CreateResultRateSeasonRelationInputDto;
  store: CreateResultRateStoreRelationInputDto;
}
export class UpdateResultRateDto {
  year?: number | null;
  month?: number | null;
  weekNumber?: number | null;
  saleRate?: number | null;
}
export class ConnectSaleActionDetailDto {
  id: string;
}

export class CreateSaleActionDetailCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSaleActionDetailProductRelationInputDto {
  connect: ConnectProductDto;
}

export class CreateSaleActionDetailDto {
  oldSalesPrice: number;
  newSalesPrice: number;
  company: CreateSaleActionDetailCompanyRelationInputDto;
  product: CreateSaleActionDetailProductRelationInputDto;
}
export class UpdateSaleActionDetailDto {
  oldSalesPrice?: number | null;
  newSalesPrice?: number | null;
}
export class ConnectSaleAnalysisDto {
  id: string;
}

export class CreateSaleAnalysisBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateSaleAnalysisBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateSaleAnalysisCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateSaleAnalysisCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSaleAnalysisSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}
export class CreateSaleAnalysisStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateSaleAnalysisDto {
  year: number;
  month: number;
  day: number;
  saleResult: number;
  saleBudget: number;
  bargainSaleResult: number;
  bargainSaleBudget: number;
  properSaleResult: number;
  properSaleBudget: number;
  grossMarginResult: number;
  grossMarginBudget: number;
  brandCategory?: CreateSaleAnalysisBrandCategoryRelationInputDto | null;
  brand: CreateSaleAnalysisBrandRelationInputDto;
  category: CreateSaleAnalysisCategoryRelationInputDto;
  company: CreateSaleAnalysisCompanyRelationInputDto;
  season: CreateSaleAnalysisSeasonRelationInputDto;
  store: CreateSaleAnalysisStoreRelationInputDto;
}
export class UpdateSaleAnalysisDto {
  year?: number | null;
  month?: number | null;
  day?: number | null;
  saleResult?: number | null;
  saleBudget?: number | null;
  bargainSaleResult?: number | null;
  bargainSaleBudget?: number | null;
  properSaleResult?: number | null;
  properSaleBudget?: number | null;
  grossMarginResult?: number | null;
  grossMarginBudget?: number | null;
}
export class ConnectSeasonBrandCategoryPlanDto {
  id: number;
}

export class CreateSeasonBrandCategoryPlanBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateSeasonBrandCategoryPlanBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateSeasonBrandCategoryPlanCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateSeasonBrandCategoryPlanCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSeasonBrandCategoryPlanSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateSeasonBrandCategoryPlanDto {
  year: number;
  month: number;
  openingStock: number;
  averagePriceBudget: number;
  averageOrderQuantityBudget: number;
  brandCategory?: CreateSeasonBrandCategoryPlanBrandCategoryRelationInputDto | null;
  brand: CreateSeasonBrandCategoryPlanBrandRelationInputDto;
  category: CreateSeasonBrandCategoryPlanCategoryRelationInputDto;
  company: CreateSeasonBrandCategoryPlanCompanyRelationInputDto;
  season: CreateSeasonBrandCategoryPlanSeasonRelationInputDto;
}
export class UpdateSeasonBrandCategoryPlanDto {
  year?: number | null;
  month?: number | null;
  openingStock?: number | null;
  averagePriceBudget?: number | null;
  averageOrderQuantityBudget?: number | null;
}
export class ConnectSeasonBrandCategoryDto {
  id: number;
}

export class CreateSeasonBrandCategoryCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSeasonBrandCategoryBrandCategoryRelationInputDto {
  connect: ConnectBrandCategoryDto;
}
export class CreateSeasonBrandCategoryBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class CreateSeasonBrandCategoryCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class CreateSeasonBrandCategorySeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateSeasonBrandCategoryDto {
  digestRateTarget: number;
  company: CreateSeasonBrandCategoryCompanyRelationInputDto;
  brandCategory?: CreateSeasonBrandCategoryBrandCategoryRelationInputDto | null;
  brand: CreateSeasonBrandCategoryBrandRelationInputDto;
  category: CreateSeasonBrandCategoryCategoryRelationInputDto;
  season: CreateSeasonBrandCategorySeasonRelationInputDto;
}
export class UpdateSeasonBrandCategoryDto {
  digestRateTarget?: number | null;
}
export class ConnectSeasonPlanDto {
  id: number;
}

export class CreateSeasonPlanCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSeasonPlanSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateSeasonPlanDto {
  year: number;
  month: number;
  properRateTarget: number;
  offRateTarget: number;
  directionMemo?: string | null;
  company: CreateSeasonPlanCompanyRelationInputDto;
  season: CreateSeasonPlanSeasonRelationInputDto;
}
export class UpdateSeasonPlanDto {
  year?: number | null;
  month?: number | null;
  properRateTarget?: number | null;
  offRateTarget?: number | null;
  directionMemo?: string | null;
}
export class ConnectSeasonDto {
  id: number;
}

export class CreateSeasonCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSeasonRefSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class CreateSeasonDto {
  name: string;
  startYearMonth: Date;
  endYearMonth: Date;
  costRateTarget: number;
  code?: string | null;
  company: CreateSeasonCompanyRelationInputDto;
  refSeason?: CreateSeasonRefSeasonRelationInputDto | null;
}

export class UpdateSeasonRefSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}

export class UpdateSeasonDto {
  name?: string | null;
  startYearMonth?: Date | null;
  endYearMonth?: Date | null;
  costRateTarget?: number | null;
  code?: string | null;
  refSeason?: UpdateSeasonRefSeasonRelationInputDto | null;
}
export class ConnectServiceDto {
  id: number;
}

export class CreateServiceDto {
  id: number;
  name: string;
  type: ServiceType;
}

export class UpdateServiceDto {
  name?: string | null;
  type?: ServiceType | null;
}
export class ConnectShopifyCustomizationDto {
  id: number;
}

export class CreateShopifyCustomizationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateShopifyCustomizationDto {
  name?: string | null;
  radialConnection?: ShopifyRadialConnection | null;
  radialConnectionNew?: RadialConnection | null;
  company: CreateShopifyCustomizationCompanyRelationInputDto;
}

export class UpdateShopifyCustomizationDto {
  name?: string | null;
  radialConnection?: ShopifyRadialConnection | null;
  radialConnectionNew?: RadialConnection | null;
}
export class ConnectShopifyIntegrationDto {
  id?: number | null;
  companyId?: string | null;
}

export class CreateShopifyIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateShopifyIntegrationDto {
  shopName: string;
  accessToken: string;
  posEcStatus: number;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  posHeadDiscountPrice: number;
  posProductUnitDiscountPrice: number;
  ecHeadDiscountPrice: number;
  ecProductUnitDiscountPrice: number;
  janCodeRecognition?: ShopifyJanCodeRecognitionKey | null;
  productSynchronized?: boolean | null;
  company: CreateShopifyIntegrationCompanyRelationInputDto;
}

export class UpdateShopifyIntegrationDto {
  shopName?: string | null;
  accessToken?: string | null;
  posEcStatus?: number | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  posHeadDiscountPrice?: number | null;
  posProductUnitDiscountPrice?: number | null;
  ecHeadDiscountPrice?: number | null;
  ecProductUnitDiscountPrice?: number | null;
  janCodeRecognition?: ShopifyJanCodeRecognitionKey | null;
  productSynchronized?: boolean | null;
}
export class ConnectShopifyOptionDto {
  id: string;
}

export class CreateShopifyOptionCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateShopifyOptionDto {
  name: ShopifyOptionType;
  index?: number | null;
  radialConnection?: RadialConnection | null;
  free?: string | null;
  company: CreateShopifyOptionCompanyRelationInputDto;
}

export class UpdateShopifyOptionDto {
  name?: ShopifyOptionType | null;
  index?: number | null;
  radialConnection?: RadialConnection | null;
  free?: string | null;
}
export class ConnectSizeDto {
  id: string;
}

export class CreateSizeCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateSizeDto {
  name?: string | null;
  code: string;
  company: CreateSizeCompanyRelationInputDto;
}
export class UpdateSizeDto {
  name?: string | null;
  code?: string | null;
}
export class ConnectSkuCustomValueSettingDto {
  id: string;
}

export class CreateSkuCustomValueSettingCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateSkuCustomValueSettingDto {
  name: string;
  type: CustomValueType;
  company: CreateSkuCustomValueSettingCompanyRelationInputDto;
}

export class UpdateSkuCustomValueSettingDto {
  name?: string | null;
  type?: CustomValueType | null;
}
export class ConnectSkuCustomValueDto {
  id: string;
}

export class CreateSkuCustomValueSkuCustomValueSettingRelationInputDto {
  connect: ConnectSkuCustomValueSettingDto;
}
export class CreateSkuCustomValueSkuRelationInputDto {
  connect: ConnectSkuDto;
}
export class CreateSkuCustomValueCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateSkuCustomValueDto {
  value: string;
  skuCustomValueSetting: CreateSkuCustomValueSkuCustomValueSettingRelationInputDto;
  sku: CreateSkuCustomValueSkuRelationInputDto;
  company: CreateSkuCustomValueCompanyRelationInputDto;
}
export class UpdateSkuCustomValueDto {
  value?: string | null;
}
export class ConnectSkuDto {
  id: string;
}

export class CreateSkuColorRelationInputDto {
  connect: ConnectColorDto;
}
export class CreateSkuCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateSkuProductRelationInputDto {
  connect: ConnectProductDto;
}
export class CreateSkuSizeRelationInputDto {
  connect: ConnectSizeDto;
}

export class CreateSkuDto {
  janCode?: string | null;
  cost?: number | null;
  smaregiProductId?: string | null;
  shopifyVariantId?: string | null;
  color: CreateSkuColorRelationInputDto;
  company: CreateSkuCompanyRelationInputDto;
  product: CreateSkuProductRelationInputDto;
  size: CreateSkuSizeRelationInputDto;
}
export class UpdateSkuDto {
  janCode?: string | null;
  cost?: number | null;
  smaregiProductId?: string | null;
  shopifyVariantId?: string | null;
}
export class ConnectSmaregiIntegrationDto {
  id?: number | null;
  companyId?: string | null;
  contractId?: string | null;
}

export class CreateSmaregiIntegrationCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateSmaregiIntegrationDto {
  contractId: string;
  productUnitDiscountPrice: number;
  productApplyBargainDiscountPrice: number;
  headDiscountPriceProportional: number;
  headDiscountCouponProportional: number;
  headProductBundleProportional: number;
  headRoundingPriceProportional: number;
  category?: SmaregiCategory | null;
  unitPrice?: SmaregiUnitPrice | null;
  productNumber?: SmaregiProductNumber | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  storeSynchronized?: boolean | null;
  warehouseSynchronized?: boolean | null;
  productSynchronized?: boolean | null;
  company: CreateSmaregiIntegrationCompanyRelationInputDto;
}

export class UpdateSmaregiIntegrationDto {
  contractId?: string | null;
  productUnitDiscountPrice?: number | null;
  productApplyBargainDiscountPrice?: number | null;
  headDiscountPriceProportional?: number | null;
  headDiscountCouponProportional?: number | null;
  headProductBundleProportional?: number | null;
  headRoundingPriceProportional?: number | null;
  category?: SmaregiCategory | null;
  unitPrice?: SmaregiUnitPrice | null;
  productNumber?: SmaregiProductNumber | null;
  lastBatchStartedAt?: Date | null;
  lastBatchEndedAt?: Date | null;
  storeSynchronized?: boolean | null;
  warehouseSynchronized?: boolean | null;
  productSynchronized?: boolean | null;
}
export class ConnectSmaregiOptionDto {
  id: string;
}

export class CreateSmaregiOptionCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateSmaregiOptionDto {
  name: SmaregiOptionType;
  radialConnection?: RadialConnection | null;
  reserveItemsCode?: string | null;
  free?: string | null;
  company: CreateSmaregiOptionCompanyRelationInputDto;
}

export class UpdateSmaregiOptionDto {
  name?: SmaregiOptionType | null;
  radialConnection?: RadialConnection | null;
  reserveItemsCode?: string | null;
  free?: string | null;
}
export class ConnectStockActionDetailDto {
  id: string;
}

export class CreateStockActionDetailSkuRelationInputDto {
  connect: ConnectSkuDto;
}
export class CreateStockActionDetailCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}

export class CreateStockActionDetailDto {
  creatorAdminUserId?: string | null;
  creatorStoreId?: string | null;
  sourceStoreId?: string | null;
  sourceWarehouseId?: string | null;
  destinationWarehouseId?: string | null;
  destinationStoreId?: string | null;
  quantity: number;
  sku?: CreateStockActionDetailSkuRelationInputDto | null;
  company: CreateStockActionDetailCompanyRelationInputDto;
}
export class UpdateStockActionDetailDto {
  creatorAdminUserId?: string | null;
  creatorStoreId?: string | null;
  sourceStoreId?: string | null;
  sourceWarehouseId?: string | null;
  destinationWarehouseId?: string | null;
  destinationStoreId?: string | null;
  quantity?: number | null;
}
export class ConnectStockDto {
  id: string;
}

export class CreateStockCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateStockServiceRelationInputDto {
  connect: ConnectServiceDto;
}
export class CreateStockSkuRelationInputDto {
  connect: ConnectSkuDto;
}
export class CreateStockStoreRelationInputDto {
  connect: ConnectStoreDto;
}
export class CreateStockWarehouseRelationInputDto {
  connect: ConnectWarehouseDto;
}

export class CreateStockDto {
  itemId: string;
  key1: string;
  key2: string;
  quantity: number;
  posId: string;
  company: CreateStockCompanyRelationInputDto;
  service: CreateStockServiceRelationInputDto;
  sku?: CreateStockSkuRelationInputDto | null;
  store?: CreateStockStoreRelationInputDto | null;
  warehouse?: CreateStockWarehouseRelationInputDto | null;
}
export class UpdateStockDto {
  itemId?: string | null;
  key1?: string | null;
  key2?: string | null;
  quantity?: number | null;
  posId?: string | null;
}
export class ConnectStoreAnalysisUpdatedDto {
  id: string;
}

export class CreateStoreAnalysisUpdatedCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateStoreAnalysisUpdatedStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateStoreAnalysisUpdatedDto {
  day: string;
  company: CreateStoreAnalysisUpdatedCompanyRelationInputDto;
  store: CreateStoreAnalysisUpdatedStoreRelationInputDto;
}
export class UpdateStoreAnalysisUpdatedDto {
  day?: string | null;
}
export class ConnectStoreAnalysisDto {
  id: string;
}

export class CreateStoreAnalysisCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateStoreAnalysisStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateStoreAnalysisDto {
  status?: number | null;
  priceAmount: number;
  purchasersCount: number;
  quantityAmount: number;
  customersCount?: number | null;
  weather?: number | null;
  maxTemperature?: number | null;
  averageTemperature?: number | null;
  minTemperature?: number | null;
  memberId?: string | null;
  description?: string | null;
  year: number;
  month: number;
  day: number;
  company: CreateStoreAnalysisCompanyRelationInputDto;
  store: CreateStoreAnalysisStoreRelationInputDto;
}
export class UpdateStoreAnalysisDto {
  status?: number | null;
  priceAmount?: number | null;
  purchasersCount?: number | null;
  quantityAmount?: number | null;
  customersCount?: number | null;
  weather?: number | null;
  maxTemperature?: number | null;
  averageTemperature?: number | null;
  minTemperature?: number | null;
  memberId?: string | null;
  description?: string | null;
  year?: number | null;
  month?: number | null;
  day?: number | null;
}
export class ConnectStoreDailyPlanDto {
  id: string;
}

export class CreateStoreDailyPlanCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateStoreDailyPlanStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateStoreDailyPlanDto {
  year: number;
  month: number;
  day: number;
  saleBudget: number;
  company: CreateStoreDailyPlanCompanyRelationInputDto;
  store: CreateStoreDailyPlanStoreRelationInputDto;
}
export class UpdateStoreDailyPlanDto {
  year?: number | null;
  month?: number | null;
  day?: number | null;
  saleBudget?: number | null;
}
export class ConnectStoreMonthlyPlanDto {
  id: string;
}

export class CreateStoreMonthlyPlanCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateStoreMonthlyPlanStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateStoreMonthlyPlanDto {
  year: number;
  month: number;
  saleBudget: number;
  company: CreateStoreMonthlyPlanCompanyRelationInputDto;
  store: CreateStoreMonthlyPlanStoreRelationInputDto;
}
export class UpdateStoreMonthlyPlanDto {
  year?: number | null;
  month?: number | null;
  saleBudget?: number | null;
}
export class ConnectStoreDto {
  id: string;
}

export class CreateStoreCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateStoreServiceRelationInputDto {
  connect: ConnectServiceDto;
}
export class CreateStoreInterstoreTransferGroupRelationInputDto {
  connect: ConnectInterstoreTransferGroupDto;
}

export class CreateStoreDto {
  cognitoSub?: string | null;
  posId?: string | null;
  name: string;
  email?: string | null;
  postalCode?: string | null;
  icon?: string | null;
  operating?: boolean | null;
  shareRate?: number | null;
  company: CreateStoreCompanyRelationInputDto;
  service?: CreateStoreServiceRelationInputDto | null;
  interstoreTransferGroup?: CreateStoreInterstoreTransferGroupRelationInputDto | null;
}
export class UpdateStoreDto {
  cognitoSub?: string | null;
  posId?: string | null;
  name?: string | null;
  email?: string | null;
  postalCode?: string | null;
  icon?: string | null;
  operating?: boolean | null;
  shareRate?: number | null;
}
export class ConnectTransactionDto {
  id: string;
}

export class CreateTransactionCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateTransactionServiceRelationInputDto {
  connect: ConnectServiceDto;
}
export class CreateTransactionStoreRelationInputDto {
  connect: ConnectStoreDto;
}

export class CreateTransactionDto {
  id: string;
  posId: string;
  posHeadId: string;
  posDetailId: string;
  completedAt: Date;
  janCode: string;
  price?: number | null;
  salesPrice: number;
  discountPrice: number;
  discountPriceProportional: number;
  totalHead: number;
  quantity: number;
  nonDiscountSubtotal: number;
  discountSubtotal: number;
  shareRatedDiscountSubtotal: number;
  isSale: boolean;
  company: CreateTransactionCompanyRelationInputDto;
  service: CreateTransactionServiceRelationInputDto;
  store?: CreateTransactionStoreRelationInputDto | null;
}
export class UpdateTransactionDto {
  posId?: string | null;
  posHeadId?: string | null;
  posDetailId?: string | null;
  completedAt?: Date | null;
  janCode?: string | null;
  price?: number | null;
  salesPrice?: number | null;
  discountPrice?: number | null;
  discountPriceProportional?: number | null;
  totalHead?: number | null;
  quantity?: number | null;
  nonDiscountSubtotal?: number | null;
  discountSubtotal?: number | null;
  shareRatedDiscountSubtotal?: number | null;
  isSale?: boolean | null;
}
export class ConnectWarehouseDto {
  id: string;
}

export class CreateWarehouseCompanyRelationInputDto {
  connect: ConnectCompanyDto;
}
export class CreateWarehouseServiceRelationInputDto {
  connect: ConnectServiceDto;
}

export class CreateWarehouseDto {
  name: string;
  posId: string;
  isIntegrated?: boolean | null;
  company: CreateWarehouseCompanyRelationInputDto;
  service?: CreateWarehouseServiceRelationInputDto | null;
}
export class UpdateWarehouseDto {
  name?: string | null;
  posId?: string | null;
  isIntegrated?: boolean | null;
}

// フィルターを適用しない場合は各項目にundefinedを入れること
export class ActionListSearchFilterDto {
  start: Date;
  end: Date;
  actionTypes?: ActionType[]; // ActionType = "SALE" | "CUSTOM"
  customActionMasterIds?: string[];
  creatorAdminUserIds?: (string | null)[]; // nullの場合はcompany
  managerAdminUserIds?: (string | null)[]; // nullの場合はcompany
  statuses?: ActionStatus[]; // ActionStatus = "PENDING" | "COMPLETED"
}
// フィルターを適用しない場合は各項目にundefinedを入れること
export class ActionRequestSearchFilterDto {
  start: Date;
  end: Date;
  stockActionType: StockActionType; // StockActionType = "STOCKMOVING" | "STOCKFOLLOW"
  sourceStoreIds?: string[]; // 在庫フォローの時は使用しない
  sourceWarehouseIds?: string[]; // 店間移動の時は使用しない
  destinationStoreIds?: string[];
}

export class ActionCreateDto {
  stockActionDetailIds: string[]; // actionに紐づけるstockActionDetailのId
  title: string;
  actionId: string;
}

export enum StockActionType {
  STOCKMOVING = 'STOCKMOVING',
  STOCKFOLLOW = 'STOCKFOLLOW'
}
export class CsvDto {
  s3Key: string;
}

export class CsvConditionDto {
  seasonIds: number[]; // すべてのときは空配列
  brandIds: number[]; // すべてのときは空配列
  categoryIds: number[]; // すべてのときは空配列
  attribute1Ids: string[]; // すべてのときは空配列
  attribute2Ids: string[];
  attribute3Ids: string[];
}

export class CsvSaleDto {
  start: Date;
  end: Date;
}
export class DailyStoreBudgetDto {
  yearMonth: string;
  storeId: string;
  budgets: number[]; // 予算を配列(配列の長さは指定年月の日の長さ)
}
export class DashboardProductRankingDto {
  start: string; // 検索条件.開始日 (yyyy-MM-dd)
  end: string; // 検索条件.終了日 (yyyy-MM-dd)
  storeIds: string[]; // 検索条件.storeId | null
  limit: number; // 取得件数 (初期値 = 10)
}

export class DashboardSaleAnalysisDto {
  start: string; // 検索条件.開始日 (yyyy-MM-dd)
  end: string; // 検索条件.終了日 (yyyy-MM-dd)
  seasonIds: number[];
  brandIds: number[];
  categoryIds: number[];
  storeIds: string[];
}
export class GroupListGraphInputDto {
  start: Date;
  end: Date;
  brands?: number[];
  seasons?: number[];
  categories?: number[];
  attributes1?: string[];
  attributes2?: string[];
  attributes3?: string[];
  customTags?: number[];
  stores?: string[];
  colors?: string[];
  sizes?: string[];
  orderBy?: string; // 'saleQuantity', 'saleResult', 'digestionRateAll', or 'digestionRateProper'
  order?: string; // 'asc' or 'desc'
  digestRate?: string; // 'quantity'(default) , 'price' or 'cost'
}
export class GetMainImagesDto {
  productIds: string[];
}

export class ImageUploadDto {
  fileNames: string[]; // ファイル名の配列
}

export class GetSkuMainImagesDto {
  skuIds: string[];
}
export class LatestItemNumberDto {
  seasonCode: string;
  brandCode: string;
  categoryCode: string;
  attribute1Code: string;
  attribute2Code: string;
  attribute3Code: string;
}

export class CreateOrderProductWithoutProductDto {
  company: CreateOrderProductCompanyRelationInputDto;
  season: CreateOrderProductSeasonRelationInputDto;
  brand: CreateOrderProductBrandRelationInputDto;
  category: CreateOrderProductCategoryRelationInputDto;
  brandCategory: CreateOrderProductBrandCategoryRelationInputDto;
  year: number;
  month: number;
  stockedDate: Date;
}

export class CreateOrderSkuWithoutSkuDto {
  company: CreateOrderSkuCompanyRelationInputDto;
  season: CreateOrderSkuSeasonRelationInputDto;
  brand: CreateOrderSkuBrandRelationInputDto;
  category: CreateOrderSkuCategoryRelationInputDto;
  brandCategory: CreateOrderSkuBrandCategoryRelationInputDto;
  product: CreateOrderSkuProductRelationInputDto;
  orderProduct: CreateOrderSkuOrderProductRelationInputDto;
  orderQuantity: number;
  orderCost: number;
}

export class CreateOrderProductFromScratchInMdMapDto {
  product: CreateProductDto;
  orderProduct: CreateOrderProductWithoutProductDto;
}

export class CreateOrderProductFromExistingProductInMdMapDto {
  productId: string;
  product: UpdateProductDto;
  orderProduct: CreateOrderProductWithoutProductDto;
}

export class CreateOrderSkuFromScratchInMdMapDto {
  sku: CreateSkuDto;
  orderSku: CreateOrderSkuWithoutSkuDto;
}

export class CreateOrderSkuFromExistingSkuInMdMapDto {
  skuId: string;
  sku: UpdateSkuDto;
  orderSku: CreateOrderSkuWithoutSkuDto;
}

export class MdMapSeasonTotalItemRatioDto {
  seasonId: number;
  brandIds?: number[]; // 配列の中身がない場合はundefinedとして送る
  categoryIds?: number[]; // 配列の中身がない場合はundefinedとして送る
  productStatuses?: ProductStatus[]; // 配列の中身がない場合はundefinedとして送る
}

export class MdMapSeasonTotalGroupListDto {
  seasonId: number;
  brandIds?: number[]; // 配列の中身がない場合はundefinedとして送る
  categoryIds?: number[]; // 配列の中身がない場合はundefinedとして送る
  productStatuses?: ProductStatus[]; // 配列の中身がない場合はundefinedとして送る
}

export class UpdateProductOrderProductInMdMapDto {
  companyId: string;
  productId: string;
  orderProductId: number;
  product: UpdateProductDto;
  orderProduct: UpdateOrderProductDto;
}

export class UpsertOrderSkuFromExistingOrderProductDto {
  orderProductId: number;
  orderSkus: UpsertOrderSkuDto[];
}

export class UpsertOrderSkuDto {
  skuId: string;
  orderQuantity: number;
  orderCost: number;
}

export class UpdateSkuCostDto {
  skuCosts: SkuCostDto[];
}
export class SkuCostDto {
  skuId: string;
  cost: number;
}
export class OrderInstructionSearchDto {
  orderedDate: OrderInstructionSearchDateDto;
  stockedDate: OrderInstructionSearchDateDto;
}

export class OrderInstructionSearchDateDto {
  start: string; // YYYY-MM-DDの形式 例: 2022-01-01
  end: string; // YYYY-MM-DDの形式 例: 2022-01-01
}

export class OrderProductSearchDto {
  orderedDate: OrderProductSearchDateDto;
  stockedDate: OrderProductSearchDateDto;
}

export class OrderProductSearchDateDto {
  start: string; // YYYY-MM-DDの形式 例: 2022-01-01
  end: string; // // YYYY-MM-DDの形式 例: 2022-01-01
}

export class OrderProductAddToInstructionDto {
  orderProductIds: number[];
  orderInstructionId: string;
}

export class OrderInstructionCreateDto {
  orderNumber: string;
  warehouseId: string;
  factoryId: string;
  orderedDate: Date;
  stockedDate: Date;
  orderProductIds: number[];
}

export class OrderInstructionDeleteDto {
  orderInstructionIds: string[];
}

export class UpdateProductBasicInfoAttribute1RelationInputDto {
  connect: ConnectAttributeDto;
}
export class UpdateProductBasicInfoAttribute2RelationInputDto {
  connect: ConnectAttributeDto;
}
export class UpdateProductBasicInfoAttribute3RelationInputDto {
  connect: ConnectAttributeDto;
}
export class UpdateProductBasicInfoSeasonRelationInputDto {
  connect: ConnectSeasonDto;
}
export class UpdateProductBasicInfoBrandRelationInputDto {
  connect: ConnectBrandDto;
}
export class UpdateProductBasicInfoCategoryRelationInputDto {
  connect: ConnectCategoryDto;
}
export class UpdateProductBasicInfoFactoryRelationInputDto {
  connect: ConnectFactoryDto;
}

export class UpdateProductBasicInfoDto {
  name?: string | null;
  itemNumber?: string | null;
  gender?: number | null;
  store?: ProductStore | null;
  price?: number | null;
  salesPrice?: number | null;
  salesStartedAt?: Date | null;
  salesEndedAt?: Date | null;
  salesPriceUpdatedAt?: Date | null;
  description?: string | null;
  composition?: string | null;
  freeComment?: string | null;
  directionMemo?: string | null;
  leadTime?: number | null;
  minimumOrderQuantity?: number | null;
  status?: ProductStatus | null;
  attribute1?: UpdateProductBasicInfoAttribute1RelationInputDto | null;
  attribute2?: UpdateProductBasicInfoAttribute2RelationInputDto | null;
  attribute3?: UpdateProductBasicInfoAttribute3RelationInputDto | null;
  season?: UpdateProductBasicInfoSeasonRelationInputDto | null;
  brand?: UpdateProductBasicInfoBrandRelationInputDto | null;
  category?: UpdateProductBasicInfoCategoryRelationInputDto | null;
  factory?: UpdateProductBasicInfoFactoryRelationInputDto | null;
}
export class ResultDownloadProductDto {
  start: Date;
  end: Date;
  masterList: ResultDownloadProductMasterListType[];
  channel: 'SUM' | 'STORE';
  result: ResultDownloadSaleStockResultListType[];
}

export class ResultDownloadSkuDto {
  start: Date;
  end: Date;
  masterList: ResultDownloadSkuMasterListType[];
  channel: 'SUM' | 'STORE';
  result: ResultDownloadSaleStockResultListType[];
}

export type ResultDownloadSkuMasterListType =
  | 'ITEM_NAME'
  | 'ITEM_NUMBER'
  | 'BRAND_NAME'
  | 'BRAND_CODE'
  | 'CATEGORY_NAME'
  | 'CATEGORY_CODE'
  | 'SEASON_NAME'
  | 'SEASON_CODE'
  | 'ATTRIBUTE1_NAME'
  | 'ATTRIBUTE1_CODE'
  | 'ATTRIBUTE2_NAME'
  | 'ATTRIBUTE2_CODE'
  | 'ATTRIBUTE3_NAME'
  | 'ATTRIBUTE3_CODE'
  | 'PRICE'
  | 'SALES_PRICE'
  | 'FACTORY_NAME'
  | 'FACTORY_CODE'
  | 'FREE_COMMENT'
  | 'CUSTOM_TAG'
  | 'ALERT'
  | 'JAN_CODE'
  | 'COLOR_NAME'
  | 'COLOR_CODE'
  | 'SIZE_NAME'
  | 'SIZE_CODE'
  | 'COST';

export type ResultDownloadProductMasterListType =
  | 'ITEM_NAME'
  | 'ITEM_NUMBER'
  | 'BRAND_NAME'
  | 'BRAND_CODE'
  | 'CATEGORY_NAME'
  | 'CATEGORY_CODE'
  | 'SEASON_NAME'
  | 'SEASON_CODE'
  | 'ATTRIBUTE1_NAME'
  | 'ATTRIBUTE1_CODE'
  | 'ATTRIBUTE2_NAME'
  | 'ATTRIBUTE2_CODE'
  | 'ATTRIBUTE3_NAME'
  | 'ATTRIBUTE3_CODE'
  | 'PRICE'
  | 'SALES_PRICE'
  | 'FACTORY_NAME'
  | 'FACTORY_CODE'
  | 'FREE_COMMENT'
  | 'CUSTOM_TAG'
  | 'ALERT';

export type ResultDownloadSaleStockResultListType =
  | 'TOTAL_SALE_QUANTITY'
  | 'SALE_QUANTITY_ON_SALE'
  | 'SALE_QUANTITY_FOR_PROPER'
  | 'TOTAL_SALES_AMOUNT'
  | 'SALE_AMOUNT_ON_SALE'
  | 'SALE_AMOUNT_FOR_PROPER'
  | 'TOTAL_DISCOUNT_PRICE'
  | 'DISCOUNT_RATE'
  | 'STOCK_COUNT'
  | 'STOCK_AMOUNT'
  | 'STORAGE_LIFE'
  | 'DIGESTION_RATE';
export class BudgetTotalDto {
  seasonPlanId: number;
  yearMonth: string;
  saleBudget: number; //売上予算
  priceSalesResultBudget: number; //定価売上予算（バックエンド側での計算省略化のために送る）
}

export class UpdateSalesBuyingPlanTotalDto {
  seasonId: number;
  budgetTotalInfos: UpdateSalesBuyingPlanTotalInfos[];
}

export class UpdateSalesBuyingPlanTotalInfos {
  seasonPlanId: number;
  properRateTarget: number; //目標プロパー売上構成比
  offRateTarget: number; //目標OFF率
}

export class UpdateSalesBuyingPlanBrandCategoryDto {
  seasonId: number;
  budgetBrandCategoryInfos: UpdateSalesBuyingPlanBrandCategoryInfos[]; // ブランド * カテゴリ毎の配列
}

export class UpdateSalesBuyingPlanBrandCategoryInfos {
  brandCategoryId: string;
  digestRateTarget: number; //目標消化率
  budgetCategoryInfos: UpdateSalesBuyingPlanCategoryInfos[];
}

export class UpdateSalesBuyingPlanCategoryInfos {
  seasonBrandCategoryPlanId: number;
  brandCategoryRate: number; //売上構成比（旧アイテム構成比）
  buyingRate: number; //仕入れ構成比
  yearMonth: string;
}

export class SalesBuyingPlanMonthBudgetSeasonRateDto {
  seasonId: number;
  brandRate: SalesBuyingPlanMonthBudgetBrandRateDto[];
}

export class SalesBuyingPlanMonthBudgetBrandRateDto {
  brandId: number;
  categoryRate: SalesBuyingPlanMonthBudgetCategoryRateDto[];
}

export class SalesBuyingPlanMonthBudgetCategoryRateDto {
  categoryId: number;
  categoryRate: SalesBuyingPlanMonthBudgetRateDto[];
}

export class SalesBuyingPlanMonthBudgetRateDto {
  yearMonth: string;
  rate: number;
}

export class SkuListInputDto {
  start: Date;
  end: Date;
  offset: number;
  limit: number;
  sort: string;
  isDesc: boolean;
  productStatus: ProductStatus[]; // 空配列ならフィルターなし
  brandIds: number[]; // 空配列ならフィルターなし
  seasonIds: number[]; // 空配列ならフィルターなし
  categoryIds: number[]; // 空配列ならフィルターなし
  attribute1Ids: string[]; // 空配列ならフィルターなし
  attribute2Ids: string[]; // 空配列ならフィルターなし
  attribute3Ids: string[]; // 空配列ならフィルターなし
  colorIds: string[]; // 空配列ならフィルターなし
  sizeIds: string[]; // 空配列ならフィルターなし
  alertMasterIds: number[]; // 空配列ならフィルターなし
  storeIds: string[]; // 空配列ならフィルターなし
}

export class SkuListActionAddSkusDto {
  actionId: string;
  skuIds: string[];
  offRate?: number; // セール以外のときはundefinedにする
}

export class SkuListActionRequestDto {
  skuId: string;
  creatorAdminUserId: string | null;
  creatorStoreId: string | null;
  sourceStoreId: string | null;
  sourceWarehouseId: string | null;
  destinationStoreId: string | null;
  destinationWarehouseId: string | null;
  quantity: number;
}
export class StoreSeasonPlanDto {
  budgetSeasonRateList: StoreSeasonPlanBudgetSeasonRateDto[];
  storeBudget: StoreSeasonPlanStoreMonthBudgetDto[];
}

export class StoreSeasonPlanBudgetSeasonRateDto {
  seasonId: number;
  monthBudgetSeasonRate: StoreSeasonPlanMonthBudgetSeasonRateDto[];
}

export class StoreSeasonPlanMonthBudgetSeasonRateDto {
  yearMonth: string;
  rate?: number;
}

export class StoreSeasonPlanStoreMonthBudgetDto {
  storeId: string;
  monthStoreBudget: StoreSeasonPlanMonthStoreBudgetDto[];
}

export class StoreSeasonPlanMonthStoreBudgetDto {
  yearMonth: string;
  saleBudget: number;
}

export class StoreSeasonSaleBudgetInfoDto {
  storeSaleBudgets: StoreSeasonBudgetDto[]; //合計の店舗別情報をまだ取得していない場合は空配列とする
  rates: StoreSeasonRateDto[]; //シーズンの開始月の月別売上予算構成比を先頭に
}

export class StoreSeasonRateDto {
  yearMonth: string; //シーズンのマスタ期間に含まれない年月もリクエストに含めるが、rateを0とする。
  rate: number;
}

export class StoreSeasonBudgetDto {
  storeId: string;
  saleBudgets: StoreSeasonMonthBudgetDto[];
}

export class StoreSeasonMonthBudgetDto {
  yearMonth: string;
  saleBudget: number;
}

export class LogizardAuthorizeDto {
  companyId: string;
  userId: string;
  password: string;
  groupId: string;
  connectionId: string;
  itemId: LogizardJanCodeRecognitionKey;
  key1: LogizardJanCodeRecognitionKey | null;
  key2: LogizardJanCodeRecognitionKey | null;
}

export class NextEngineAuthorizeDto {
  uid: string;
  state: string;
  janCode: NextEngineJanCodeRecognitionKey;
  stockSynchronized?: boolean | null;
}

export class ShopifyAuthorizeDto {
  company: CreateShopifyIntegrationCompanyRelationInputDto;
  shopName: string;
  posEcStatus: number; //posで連携するときは:0 ECで連携するのは:1
  accessToken: string;
  posHeadDiscountPrice: number; //0: sale, 1: proper
  posProductUnitDiscountPrice: number; //0: sale, 1: proper
  ecHeadDiscountPrice: number; //0: sale, 1: proper
  ecProductUnitDiscountPrice: number; //0: sale, 1: proper
  janCodeRecognition: ShopifyJanCodeRecognitionKey;
}

export class ShopifyStoreItemDto {
  locationName: string;
  posId: string; //locationId
  posEcWarehouseStatus: number; //未選択:0 pos:1 EC:2 warehouse:3
}

export class ShopifyStoreDto {
  stores: ShopifyStoreItemDto[];
}

export class ShopifyProductInputDto {
  title?: string;
  body_html?: string;
  vendor?: string;
  product_type?: string;
  handle?: string;
  tags?: string;
  variants?: ShopifyVariantInputDto[];
  options?: {
    name?: string;
    values?: string[];
  }[];
}

export class ShopifyVariantInputDto {
  price?: number;
  sku?: string;
  compare_at_price?: number;
  option1?: string;
  option2?: string;
  option3?: string;
  taxable?: boolean;
  barcode?: string;
  requires_shipping?: boolean;
  inventory_management?: string | null;
}
export class SmaregiCallbackDto {
  state: string;
  file_url?: string[];
}

export class SmaregiCallbackStoresDto {
  contractId: string;
  event: string;
  action: string;
  storeIds: string[];
}

export class SmaregiCreateProductDto {
  categoryId: string;
  productCode: string;
  productName: string;
  productKana?: string;
  taxDivision?: string;
  productPriceDivision?: string;
  price: string;
  customerPrice?: string;
  cost?: string;
  attribute?: string;
  description?: string;
  catchCopy?: string;
  size?: string;
  color?: string;
  tag?: string;
  groupCode?: string;
  url?: string;
  printReceiptProductName?: string;
  displaySequence?: string;
  displayFlag?: string;
  division?: string;
  productOptionGroupId?: string;
  salesDivision?: string;
  stockControlDivision?: string;
  pointNotApplicable?: string;
  taxFreeDivision?: string;
  calcDiscount?: string;
  staffDiscountRate?: string;
  useCategoryReduceTax?: string;
  reduceTaxId?: string;
  reduceTaxPrice?: string;
  reduceTaxCustomerPrice?: string;
  orderPoint?: string;
  purchaseCost?: string;
  supplierProductNo?: string;
  appStartDateTime?: string;
  reserveItems?: {
    no: string;
    value?: string;
  }[];
  prices?: {
    storeId: string;
    priceDivision: string;
    startDate: string;
    endDate?: string;
    price: string;
  }[];
  stores?: {
    storeId: string;
    assignDivision?: string;
  }[];
  inventoryReservations?: {
    reservationProductId: string;
    reservationAmount: string;
  }[];
  attributeItems?: {
    code: string;
    no: string;
  }[];
  orderSetting?: {
    continuationDivision?: string;
    orderStatusDivision?: string;
    orderNoReasonDivision?: string;
    orderUnit?: {
      division?: string;
      num?: string;
      name?: string;
    };
    orderLimitAmount?: string;
    pbDivision?: string;
    displayFlag?: string;
    stores?: {
      storeId?: string;
      orderLimitAmount?: string;
      displayFlag?: string;
    }[];
  };
}

export class SmaregiUpdateProductDto {
  categoryId?: string;
  productCode?: string;
  productName?: string;
  productKana?: string;
  taxDivision?: string;
  productPriceDivision?: string;
  price?: string;
  customerPrice?: string;
  cost?: string;
  attribute?: string;
  description?: string;
  catchCopy?: string;
  size?: string;
  color?: string;
  tag?: string;
  groupCode?: string;
  url?: string;
  printReceiptProductName?: string;
  displaySequence?: string;
  displayFlag?: string;
  division?: string;
  productOptionGroupId?: string;
  salesDivision?: string;
  stockControlDivision?: string;
  pointNotApplicable?: string;
  taxFreeDivision?: string;
  calcDiscount?: string;
  staffDiscountRate?: string;
  useCategoryReduceTax?: string;
  reduceTaxId?: string;
  reduceTaxPrice?: string;
  reduceTaxCustomerPrice?: string;
  orderPoint?: string;
  purchaseCost?: string;
  supplierProductNo?: string;
  appStartDateTime?: string;
  reserveItems?: {
    no: string;
    value?: string;
  }[];
  prices?: {
    storeId: string;
    priceDivision: string;
    startDate: string;
    endDate?: string;
    price: string;
  }[];
  stores?: {
    storeId: string;
    assignDivision?: string;
  }[];
  inventoryReservations?: {
    reservationProductId: string;
    reservationAmount: string;
  }[];
  attributeItems?: {
    code?: string;
    no: string;
  }[];
  orderSetting?: {
    continuationDivision?: string;
    orderStatusDivision?: string;
    orderNoReasonDivision?: string;
    orderUnit?: {
      division?: string;
      num?: string;
      name?: string;
    };
    orderLimitAmount?: string;
    pbDivision?: string;
    displayFlag?: string;
    stores?: {
      storeId?: string;
      orderLimitAmount?: string;
      displayFlag?: string;
    }[];
  };
}
export type CustomValueType = 'STRING' | 'NUMBER' | 'DATE';

export type CompanyPlan = 'STANDARD' | 'STORE' | 'MD' | 'FULL';

export type SmaregiCategory =
  | 'BRANDCODE'
  | 'CATEGORYCODE'
  | 'ITEMNUMBER'
  | 'JANCODE'
  | 'ATTRIBUTE1NAME'
  | 'ATTRIBUTE1CODE'
  | 'ATTRIBUTE2NAME'
  | 'ATTRIBUTE2CODE'
  | 'ATTRIBUTE3NAME'
  | 'ATTRIBUTE3CODE';

export type SmaregiUnitPrice = 'PRICE' | 'SALESPRICE';

export type SmaregiProductNumber = 'JANCODE' | 'ITEMNUMBERCOLORCODESIZECODE' | 'ITEMNUMBERSIZECODECOLORCODE' | 'EMPTY';

export type FutureshopJanCodeRecognitionKey = 'JANCODE' | 'SKUNO';

export type ShopifyRadialConnection =
  | 'JANCODE'
  | 'ITEMNUMBERCOLORCODESIZECODE'
  | 'ITEMNUMBERSIZECODECOLORCODE'
  | 'BRANDNAME'
  | 'BRANDCODE'
  | 'CATEGORYNAME'
  | 'CATEGORYCODE'
  | 'ITEMNAME'
  | 'ITEMNUMBER'
  | 'SALESPRICE'
  | 'PRICE'
  | 'COST'
  | 'SALESSTARTEDAT'
  | 'SALESPRICEUPDATEDAT'
  | 'DESCRIPTION'
  | 'COLORCODE'
  | 'COLORNAME'
  | 'SIZECODE'
  | 'SIZENAME'
  | 'ATTRIBUTE1'
  | 'ATTRIBUTE2'
  | 'ATTRIBUTE3';

export type BaseJanCodeRecognitionKey = 'BARCODE' | 'VARIATIONIDENTIFIER';

export type MakeshopJanCodeRecognitionKey = 'JANCODE' | 'ORIGINALCODE';

export type NextEngineJanCodeRecognitionKey = 'JANCODE' | 'GOODSID';

export type LogizardJanCodeRecognitionKey = 'JANCODE' | 'ITEMNUMBER' | 'COLORCODE' | 'SIZECODE';

export type ShopifyJanCodeRecognitionKey = 'SKU' | 'BARCODE';

export type ProductStatus = 'PLANNING' | 'FIRSTSAMPLE' | 'SECONDSAMPLE' | 'DROP' | 'ORDERED';

export type ProductStore = 'ALL' | 'STORE' | 'EC';

export type OrderInstructionStatus = 'PREORDER' | 'ORDERED' | 'PARTLYSTOCKED' | 'STOCKED' | 'DROP';

export type OrderProductStockedStatus = 'PREORDER' | 'ORDERED' | 'STOCKED' | 'DROP';

export type ServiceType = 'POS' | 'EC' | 'WMS';

export type ActionType = 'SALE' | 'CUSTOM' | 'STOCKMOVING' | 'STOCKFOLLOW';

export type ActionStatus = 'PENDING' | 'COMPLETED';

export type SmaregiOptionType =
  | 'PRODUCTKANA'
  | 'TAXDIVISION'
  | 'PRODUCTPRICEDIVISION'
  | 'CUSTOMERPRICE'
  | 'COST'
  | 'ATTRIBUTE'
  | 'ITEMNUMBER'
  | 'DESCRIPTION'
  | 'CATCHCOPY'
  | 'SIZE'
  | 'COLOR'
  | 'TAG'
  | 'GROUPCODE'
  | 'URL'
  | 'PRINTRECEIPTPRODUCTNAME'
  | 'DISPLAYSEQUENCE'
  | 'DISPLAYFLAG'
  | 'DIVISION'
  | 'PRODUCTOPTIONGROUPID'
  | 'SALESDIVISION'
  | 'STOCKCONTROLDIVISION'
  | 'POINTNOTAPPLICABLE'
  | 'TAXFREEDIVISION'
  | 'CALCDISCOUNT'
  | 'STAFFDISCOUNTRATE'
  | 'USECATEGORYREDUCETAX'
  | 'REDUCETAXID'
  | 'REDUCETAXPRICE'
  | 'REDUCETAXCUSTOMERPRICE'
  | 'ORDERPOINT'
  | 'PURCHASECOST'
  | 'SUPPLIERPRODUCTNO'
  | 'APPSTARTDATETIME'
  | 'RESERVEITEMS'
  | 'PRICES'
  | 'STORES'
  | 'INVENTORYRESERVATIONS'
  | 'ATTRIBUTEITEMS'
  | 'ORDERSETTING';

export type RadialConnection =
  | 'JANCODE'
  | 'ITEMNUMBERCOLORCODESIZECODE'
  | 'ITEMNUMBERSIZECODECOLORCODE'
  | 'BRANDNAME'
  | 'BRANDCODE'
  | 'CATEGORYNAME'
  | 'CATEGORYCODE'
  | 'ITEMNAME'
  | 'ITEMNUMBER'
  | 'SALESPRICE'
  | 'PRICE'
  | 'COST'
  | 'SALESSTARTEDAT'
  | 'SALESPRICEUPDATEDAT'
  | 'SALESENDEDAT'
  | 'DESCRIPTION'
  | 'COLORCODE'
  | 'COLORNAME'
  | 'SIZECODE'
  | 'SIZENAME'
  | 'COMPOSITION'
  | 'FREECOMMENT'
  | 'DIRECTIONMEMO'
  | 'FACTORYNAME'
  | 'FACTORYCODE'
  | 'ATTRIBUTE1NAME'
  | 'ATTRIBUTE1CODE'
  | 'ATTRIBUTE2NAME'
  | 'ATTRIBUTE2CODE'
  | 'ATTRIBUTE3NAME'
  | 'ATTRIBUTE3CODE'
  | 'SEASONNAME'
  | 'SEASONCODE'
  | 'CUSTOMPRODUCT'
  | 'CUSTOMSKU'
  | 'EMPTY';

export type ShopifyOptionType =
  | 'TITLE'
  | 'BODYHTML'
  | 'VENDOR'
  | 'PRODUCTTYPE'
  | 'HANDLE'
  | 'TAGS'
  | 'PRICE'
  | 'SKU'
  | 'COMPAREATPRICE'
  | 'OPTION1NAME'
  | 'OPTION1VALUE'
  | 'OPTION2NAME'
  | 'OPTION2VALUE'
  | 'OPTION3NAME'
  | 'OPTION3VALUE'
  | 'TAXABLE'
  | 'BARCODE'
  | 'REQUIRESSHIPPING'
  | 'INVENTORYMANAGEMENT';
